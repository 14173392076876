var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("page-header", {
        attrs: {
          "disease-group": _vm.$parent.diseaseGroup,
          "front-contents": _vm.$parent.frontContents,
        },
      }),
      _c("div", { staticClass: "container-fluid mdkp-body notfound" }, [
        _c("div", { staticClass: "card mdkp-card" }, [
          _c("div", { staticClass: "row card-body" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _vm._m(0),
              _c("div", { staticClass: "notice" }, [
                _c("h4", [_vm._v("Page Not Found")]),
                _c("div", [
                  _c("div", [
                    _vm._v(" We couldn't find the page you're looking for. "),
                  ]),
                  _c("div", [
                    _vm._v(" The requested link "),
                    _c("span", { staticClass: "path" }, [
                      _vm._v(_vm._s(_vm.$parent.currentPath)),
                    ]),
                    _vm._v(" is not found on this site. "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "button" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            pill: "",
                            variant: "outline-success",
                            href: "/",
                          },
                        },
                        [_vm._v("Home")]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            pill: "",
                            variant: "outline-warning",
                            href: "//kp4cd.org/contact",
                          },
                        },
                        [_vm._v("Contact")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("page-footer", {
        attrs: { "disease-group": _vm.$parent.diseaseGroup },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page" }, [
      _c("span", { staticClass: "number" }, [_vm._v("4")]),
      _c("div", { staticClass: "DNA" }, [
        _c("div", { staticClass: "helix" }),
        _c("div", { staticClass: "bases at" }),
        _c("div", { staticClass: "bases ta" }),
        _c("div", { staticClass: "bases at" }),
        _c("div", { staticClass: "bases at" }),
        _c("div", { staticClass: "bases gc" }),
        _c("div", { staticClass: "bases ta" }),
        _c("div", { staticClass: "bases gc" }),
        _c("div", { staticClass: "bases gc" }),
        _c("div", { staticClass: "bases gc" }),
        _c("div", { staticClass: "bases at" }),
        _c("div", { staticClass: "bases at" }),
        _c("div", { staticClass: "bases ta" }),
        _c("div", { staticClass: "bases at" }),
        _c("div", { staticClass: "bases at" }),
        _c("div", { staticClass: "bases gc" }),
        _c("div", { staticClass: "bases gc" }),
        _c("div", { staticClass: "bases cg" }),
        _c("div", { staticClass: "bases gc" }),
        _c("div", { staticClass: "bases at" }),
        _c("div", { staticClass: "bases ta" }),
        _c("div", { staticClass: "bases ta" }),
        _c("div", { staticClass: "bases cg" }),
        _c("div", { staticClass: "bases cg" }),
      ]),
      _c("span", { staticClass: "number" }, [_vm._v("4")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }